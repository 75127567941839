<template>
  <v-dialog v-model="modalEmployeeClockIn" width="600" persistent @close="setModalEmployeeClockIn(false)"
    transition="scroll-x-transition">
    <v-card class="main-card-delete">
      <v-card-actions class="card-action-delete">
        <v-list-item-title style="font-size: 18px; font-weight: 300" class="title-header-form ml-5">
          Add Work Sheet
        </v-list-item-title>
        <v-btn icon depressed @click="setModalEmployeeClockIn(false)">
          <v-icon class="icon-style">{{ icon }}</v-icon>
        </v-btn>
      </v-card-actions>
      <!-- <v-divider></v-divider> -->
      <div class="from-content">
        <v-form class="form" ref="form">
          <v-row>
            <!-- <v-col cols="12" md="6" lg="6">
              <label class="label-input">{{
                $t("groupSchedule.roster.rosterOption.date.startDate")
              }}</label>
              <DatePicker
                class="date-time"
                style="width: 100%"
                type="date"
                valueType="format"
                slot="activator"
                :append-to-body="true"
                name="founding_date"
                v-model="start_date"
              ></DatePicker>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <label class="label-input">{{
                $t("groupSchedule.roster.rosterOption.date.endDate")
              }}</label>
              <DatePicker
                class="date-time"
                style="width: 100%"
                type="date"
                valueType="format"
                slot="activator"
                :append-to-body="true"
                v-model="end_date"
                name="founding_date"
              >
              </DatePicker>
            </v-col> -->
            <v-col cols="12">
              <template>
                <v-layout row wrap>
                  <v-flex>
                    <v-menu ref="dateItem" v-model="dateItem" :close-on-content-click="false" :nudge-right="40"
                      :return-value.sync="dates" transition="scale-transition" offset-y min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-combobox v-model="dates" multiple outlined filled chips small-chips item-text="name"
                          item-value="id" color="blue-grey lighten-4"
                          :label="$t('groupSchedule.roster.rosterOption.date.chooseDate')" v-on="on">
                        </v-combobox>
                      </template>
                      <v-date-picker v-model="dates" multiple no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text outlined @click="dateItem = false">Cancel</v-btn>
                        <v-btn class="btn-save-change" outlined @click="$refs.dateItem.save(dates)">OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-flex>
                </v-layout>
              </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <br />
              <v-select label="Work Sheet" outlined :items="scheduleDetail" :item-text="getRosterFieldText"
                item-value="id" multiple v-model="scheduleDetailId" @change="setSelectedSchedule"></v-select>

              <v-autocomplete filled chips color="blue-grey lighten-4" label="Select Employee" item-text="name"
                item-value="id" multiple outlined :items="allEmployee" hide-selected v-model="data.employee">
                <template v-slot:selection="data">
                  <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select"
                    @click:close="removeItemFromTable(data.item)">
                    <v-avatar left>
                      <v-img :src="data.item.urlProfile"></v-img>
                    </v-avatar>
                    {{ data.item.name }} {{ data.item.surname }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar>
                      <img :src="data.item.urlProfile" alt="" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      <v-list-item-subtitle v-html="data.item.surname"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-checkbox v-model="emp_weeken" label="Weekly Day Off" @change="setEmpWeeken"></v-checkbox>
            </v-col>

            <v-col cols="8">
              <v-btn class="btn-save-change" :loading="btnLoading" @click="saveWorkSheet">
                {{ $t("branch.save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment"; // import xlsx;
export default {
  props: {
    date: "",
    visible: {
      default: false,
    },
    icon: {
      default: "mdi-close",
      type: String,
    },
    emp_id: "",
    allEmployee: [],
  },
  data() {
    return {
      data: {
        employee: [],
        // date:[]
      },
      start_date: "",
      end_date: "",
      btnLoading: false,
      modalEmployeeClockIn: false,
      scheduleDetail: {},
      scheduleDetailId: [],
      emp_weeken: false,
      server_errors: {
        employee_id: "",
      },
      dates: [],
      dateItem: false
    };
  },
  watch: {
    date(val) {
      this.start_date = moment(val).format("YYYY-MM-DD");
      this.end_date = moment(val).format("YYYY-MM-DD");
    },
    visible(val) {
      this.modalEmployeeClockIn = val;
    },
    province_id: function (provinceID) {
      this.FilterDistricts(provinceID);
    },
    emp_id: function (emp_id) {
      this.fetchChoiseEmployee(emp_id);
    },
    date(newDate) {
      this.dates = [newDate];
    }
    
  },
  // mounted() {
  //   // ใช้ console.log() เพื่อดูค่าของ date ที่ได้รับจาก props
  //   console.log("Date received in modal:", this.date);
  // },
  methods: {
    fetchChoiseEmployee(emp_id) {
      this.data.employee = [emp_id];
      // this.data.date = [date];
    },
    getRosterFieldText(item) {
      // if (!item) return "null"; 
      return `${item.name} (${item.check_in_before}-${item.check_out_after})`;
    },
    removeItemFromTable(item) {
      const index = this.data.employee.indexOf(item.id);
      if (index >= 0) {
        this.data.employee.splice(index, 1);
      }
    },
    saveWorkSheet() {
      const item = {
        scheduleDetailId: this.scheduleDetailId,
        emp_weeken: this.emp_weeken,
        employee_id: this.data.employee,
        dates: this.dates
      };

      this.$axios
        .post(`company/roster/add/employee/work/sheet`, item)
        .then((res) => {
          if (res.data.code === 200) {
            this.scheduleDetailId = [];
            this.data.employee = [];
            this.setModalEmployeeClockIn(false);
            this.$emit("success", true);

            this.scheduleDetailId = "",
              this.emp_weeken = "",
              this.data.employee = "",
              this.dates = null
          }
          console.log('ressss=>>>', res)
        })
        .catch((error) => {
          if (error.response.status === 422) {
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
              this.$notification.ShowErrors(
                "top-right",
                "danger",
                3000,
                (this.server_errors[key] = value[0])
              );
            }
          } else if (error.response.status === 400) {
            this.$notification.ShowErrors(
              "top-right",
              "danger",
              3000,
              error.response.data.message
            );
          }
        });
    },
    setModalEmployeeClockIn(isVisible) {
      this.modalEmployeeClockIn = isVisible;
      this.$emit("change", isVisible);
    },
    fetchScheduleDetail() {
      this.$axios.get(`company/get/schedule/to/add/roster`).then((res) => {
        if (res.data.code === 200) {
          this.scheduleDetail = res.data.data;
        }
      });
    },
    setEmpWeeken() {
      if (this.emp_weeken) {
        this.scheduleDetailId = false;
      }
    },
    setSelectedSchedule() {
      this.emp_weeken = false;
    },
  },
  created() {
    this.fetchScheduleDetail();
  },
};
</script>

<style scoped lang="scss">
.from-content {
  width: 600px;
  height: auto;
  padding: 40px 60px;
  overflow: hidden;

  .from {
    height: 450px;
    padding: 20px 40px;
  }
}

.title-header-form {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 24px;
  letter-spacing: 1px;
  color: #4a4a4a;
}
</style>