<template>
  <div class="step-layout">
    <div class="step-content">
      <section class="personal">
        <v-form ref="form">
          <v-row class="mt-8">
            <v-col cols="5" md="3">
              <div class="form-title">
                <h1>
                  <span><i class="fas fa-address-card"></i></span>{{ $t("Employee.form.empInfo") }}
                </h1>
              </div>
            </v-col>
            <v-col cols="7" md="9">
              <v-row class="personal-input">
                <v-col class="mb-6" cols="12">
                  <div class="choose-image">
                    <div class="show-image">
                      <i class="fas fa-cloud-upload-alt"></i>
                      <input @change="PreviewImage" type="file" class="input-file-image" />
                      <p>{{ $t("Employee.form.selectProfile") }}</p>
                      <img v-if="showImage || employee.urlProfile" :src="showImage || employee.urlProfile" alt="" />
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="12">
                  <label class="label-input"><span class="text-alert">*</span>{{ $t("Employee.form.empId") }}</label>
                  <v-text-field @keydown.enter="focusNext" dense outlined :rules="nameRules" required
                    v-model="employee.emp_number" :hint="`${server_errors.emp_number}`" persistent-hint>
                  </v-text-field>
                </v-col>
                <v-col cols="6" md="6">
                  <label class="label-input"><span class="text-alert">*</span>{{ $t("Employee.form.name") }}</label>
                  <v-text-field @keydown.enter="focusNext" dense outlined :rules="nameRules" required
                    v-model="employee.name" :hint="`${server_errors.name}`" persistent-hint>
                  </v-text-field>
                </v-col>
                <v-col cols="6" md="6">
                  <label class="label-input"><span class="text-alert">*</span>{{ $t("Employee.form.surname") }}</label>
                  <v-text-field @keydown.enter="focusNext" dense outlined v-model="employee.surname" :rules="nameRules"
                    :hint="`${server_errors.surname}`" persistent-hint requried>
                  </v-text-field>
                </v-col>

                <v-col cols="6" md="6">
                  <label class="label-input">{{
                    $t("Employee.form.en_name")
                  }}</label>
                  <v-text-field @keydown.enter="focusNext" dense outlined v-model="employee.en_name"
                    :hint="`${server_errors.en_name}`" persistent-hint>
                  </v-text-field>
                </v-col>
                <v-col cols="6" md="6">
                  <label class="label-input">{{
                    $t("Employee.form.en_surname")
                  }}</label>
                  <v-text-field @keydown.enter="focusNext" dense outlined v-model="employee.en_surname"
                    :hint="`${server_errors.en_surname}`" persistent-hint>
                  </v-text-field>
                </v-col>

                <v-col cols="6" md="6">
                  <label class="label-input"><span class="text-alert">*</span>{{ $t("Employee.form.dob") }}</label>
                  <DatePicker class="date-time" style="width: 100%" type="date" valueType="format" slot="activator"
                    :append-to-body="true" v-model="employee.birth_date" :rules="nameRules" requried></DatePicker>
                </v-col>

                <v-col cols="6" md="6">
                  <label class="label-input"><span class="text-alert">*</span>{{ $t("Employee.form.Nationality")
                  }}</label>
                  <v-select dense outlined :items="nationality" item-text="name" item-value="id"
                    v-model="employee.nationality_id" :rules="nameRules" requried
                    :hint="`${server_errors.nationality_id}`" persistent-hint>
                  </v-select>
                </v-col>
                <v-col cols="12" md="12" lg="12">
                  <label class="label-input"><span class="text-alert">*</span>{{ $t('Employee.form.gender') }}</label>
                  <v-radio-group row v-model="employee.gender">
                    <v-radio class="label-input" value="Male" :label="$t('Employee.form.male')">
                    </v-radio>
                    <v-radio class="label-input" value="Female" :label="$t('Employee.form.female')">
                    </v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" md="12" class="d-flex flex-column">
                  <div class="choose-image">
                    <div class="show-image">
                      <i class="fas fa-cloud-upload-alt"></i>
                      <input @change="PreviewPDF" type="file" class="input-file-image" />
                      <p>Resume File(PDF)</p>
                      <embed class="embed-pdf" type="application/pdf" :src="showPDF || employee.resumeFile" />
                    </div>
                  </div>
                  <!-- <p>{{ employee.resumeFile }}</p> -->
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-col cols="12" md="12">
            <v-btn class="btn-next btn-save-change" @click="saveChange" :loading="btnLoading">
              Update <span><i class="fal fa-long-arrow-right"></i></span>
            </v-btn>
          </v-col>
        </v-form>
      </section>
    </div>
    <Loading v-if="isLoading" />
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import pdf from "vue-pdf";
import Loading from "@/components/Loading";

export default {
  props: {
    employee: {
      default() {
        return {};
      },
    },
  },
  components: {
    DatePicker,
    pdf,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      btnLoading: false,
      nameRules: [(v) => !!v || "ກະລຸນາປ້ອນ..."],
      employee_id: this.$route.params.employee_id,
      imageFile: "",
      showImage: "",
      showPDF: "",
      imagePDF: "",

      nationality: [],
      profile: "",
      resumeFile: "",

      server_errors: {
        gender: "",
        name: "",
        surname: "",
        en_name: "",
        en_surname: "",
        birth_date: "",
        nationality_id: "",
        profile: "",
        emp_number: "",
      },
    };
  },
  methods: {
    focusNext(e) {
      const inputs = Array.from(
        e.target.form.querySelectorAll('input[type="text"]')
      );
      const index = inputs.indexOf(e.target);
      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },

    PreviewPDF: function (event) {
      const input = event.target;

      const file = input.files[0];
      const fileType = file["type"];
      const validImageTypes = ["application/pdf"];
      if (!validImageTypes.includes(fileType)) {
        this.$notification.ShowErrors(
          "top-right",
          "danger",
          3000,
          "image must be PDF"
        );
      } else if (input.files && input.files[0]) {
        this.imagePDF = input.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.showPDF = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.UploadPDF(this.imagePDF);
      }
    },

    UploadPDF(imagePDF) {
      let formData = new FormData();
      formData.append("imageFile", imagePDF);
      this.$axios.post(`upload-file`, formData).then((res) => {
        if (res.status === 200) {
          this.resumeFile = res.data.filename;
        }
      });
    },

    PreviewImage: function (event) {
      this.isLoading = true;
      const input = event.target;
      const file = input.files[0];
      const fileType = file["type"];
      const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!validImageTypes.includes(fileType)) {
        this.$notification.ShowErrors(
          "top-right",
          "danger",
          3000,
          "image must be jpeg,png"
        );
        this.isLoading = false;
      } else if (input.files && input.files[0]) {
        this.imageFile = input.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.showImage = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.UploadImage(this.imageFile);
      }
    },

    UploadImage(imageFile) {
      let formData = new FormData();
      formData.append("imageFile", imageFile);
      this.$axios.post(`upload-file`, formData).then((res) => {
        if (res.status === 200) {
          this.profile = res.data.filename;
          this.isLoading = false;
        }
      });
    },

    // removeImage() {
    //   this.showImage = "";
    //   this.imageFile = ""
    // },
    fetchNationality() {
      this.$axios
        .get(`nationality`)
        .then((res) => {
          if (res) {
            this.nationality = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    validateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
      }
    },

    saveChange() {
      this.btnLoading = true;
      const items = {
        name: this.employee.name,
        surname: this.employee.surname,
        en_name: this.employee.en_name,
        en_surname: this.employee.en_surname,
        gender: this.employee.gender,
        birth_date: this.employee.birth_date,
        profile: this.profile ?? "",
        nationality_id: this.employee.nationality_id,
        resumeFile: this.resumeFile ?? "",
        emp_number: this.employee.emp_number,
      };
      this.$axios
        .put(`company/edit/employee/basic/info/${this.employee_id}`, items)
        .then((res) => {
          if (res.status === 200) {
            this.btnLoading = false;
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
          }
        })
        .catch((error) => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
          this.btnLoading = false;
        });
    },
  },
  created() {
    this.fetchNationality();
  },
};
</script>

<style lang="scss">
.choose-image {
  margin: 0px !important;
}

.step-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .personal {
    width: 900px;
    height: auto;
    //background-color: red;
    //padding-top: 20px;
    padding-bottom: 40px;
    margin-bottom: 40px;

    .form-title {
      font-family: $font-family;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      padding-left: 20px;
      padding-top: 10px;

      h1 {
        font-size: 1.2rem;
        letter-spacing: 1px;
        font-weight: normal;
        color: #0978ea;

        span {
          color: #0978ea;
          margin-right: 8px;
        }
      }
    }

    .personal-input {
      width: 100%;
      height: 100%;
      //  background-color: green;
      padding-top: 20px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 20px;
    }

    .btn-addFieldBranch {
      margin-top: 25px;
    }
  }

  .btn-next {
    width: 655px;
    height: 40px;
    background-color: $main-btn-color;
    margin-top: 20px;
    float: left;
    border-radius: 4px;
    margin-left: 212px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-weight: 400;
    cursor: pointer;
    font-family: $font-family;

    span {
      margin-left: 8px;

      i {
        font-size: 1.2rem;
        margin-top: 4px;
        font-weight: bold;
      }
    }
  }
}
</style>
