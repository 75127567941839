<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1 class="mt-5">
          {{ $t("restDay.title")
          }}<span @click="showHolidayDetail">
            <i class="fal fa-info-circle holiday-detail"></i></span>
        </h1>
      </div>
    </div>

    <v-row class="mb-6 mt-6">
      <v-col cols="12" md="4" lg="4"> </v-col>
      <v-col cols="12" md="3" lg="3" class="mt-3">
        <v-card elevation="2">
          <v-row class="pa-1">
            <v-col cols="12" md="3" lg="3" sm="3" class="d-flex justify-center"><v-btn icon color="info" @click="prev">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn></v-col>
            <v-col cols="12" md="6" lg="6" sm="6" class="d-flex justify-center">
              <div class="show--current--year mt-2" v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </div>
            </v-col>
            <v-col cols="12" md="3" lg="3" sm="3" class="d-flex justify-center"><v-btn icon color="info" @click="next">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn></v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" lg="4">
        <button class="btn-active-today" @click="setToday">Today</button>
        <button class="btn-active-today lfont" @click="onCreate()">
          {{ $t("restDay.create") }}
        </button>
      </v-col>
    </v-row>
    <!-- <div class="section-action">
      <div class="search-action">
        <div class="section--left-calendar">
          <div class="group-btn">
            <button class="button-left is-secondary-left" @click="prev">
              <div class="wrapper">
                <i class="fas fa-angle-left"></i>
              </div>
            </button>
            <button class="button-right is-secondary-right" @click="next">
              <div class="wrapper">
                <i class="fas fa-angle-right"></i>
              </div>
            </button>
          </div>
          <button class="btn-active-today" @click="setToday">Today</button>
          <div class="show--current--year" v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </div>
        </div>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          {{ $t("restDay.create") }}
        </v-btn>
      </div>
    </div> -->
    <div class="section-form-content">
      <div class="block-content">
        <v-row>
          <v-col>
            <v-sheet height="1000">
              <v-calendar ref="calendar" v-model="focus" :events="events" @change="fetchRestDay" event-timed="none"
                @click:event="openModalEditRestDay"></v-calendar>
            </v-sheet>
          </v-col>
        </v-row>
      </div>
      <Loading v-if="isLoading" />
      <modalEditRestDay :visible="modalEditRestDay" :valueEdit="valueEditRestDay"
        @success="fetchRestDay({ start: currentStart, end: currentEnd })" @change="(val) => (modalEditRestDay = val)" />

      <showDescriptionHoliday :visible="modalShowDescriptionHoliday"
        @change="(val) => (modalShowDescriptionHoliday = val)" />
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import modalEditRestDay from "../../../components/restDay/EditRestDay";
import Loading from "../../../components/Loading";
import showDescriptionHoliday from "./showDescriptionHoliday";

export default {
  components: {
    modalEditRestDay,
    Loading,
    showDescriptionHoliday,
  },

  data() {
    return {
      currentStart: null,
      currentEnd: null,
      isLoading: false,
      valueEditRestDay: {},
      listRestDay: [],
      restDayName: [],
      modalEditRestDay: false,
      modalShowDescriptionHoliday: false,
      focus: "",
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      dayNames: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
    };
  },

  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    showHolidayDetail() {
      this.modalShowDescriptionHoliday = true;
    },

    openModalEditRestDay(val) {
      this.valueEditRestDay = val.event;
      this.modalEditRestDay = true;
    },
    setToday() {
      this.focus = "";
    },
    onCreate() {
      this.$router.push({ name: "restDay.create" }).catch(() => { });
    },

    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    // eslint-disable-next-line no-unused-vars
    fetchRestDay({ start, end }) {
      this.currentStart = start;
      this.currentEnd = end;
      this.isLoading = true;
      this.events = [];
      this.$axios
        .get(`company/rest-day`)
        .then((res) => {
          this.isLoading = false;
          this.listRestDay = res.data.data.data;
          const events = [];
          this.listRestDay.map((item) => {
            const value = item.value.toLowerCase();
            const startDate = moment(start.date);

            const startSet = startDate.clone().subtract(5, "day");
            const endSet = moment(end.date)
              .clone()
              .add(5, "day");

            const currentYear = startDate.clone().year();
            const currentMonth = startDate.clone().month() + 1;

            let startDay = startSet
              .clone()
              .startOf("month")
              .date();
            let endDay = endSet
              .clone()
              .endOf("month")
              .date();

            if (item.type === "weekly") {
              while (startDay <= endDay) {
                const tuesday = startSet.day(value);
                events.push({
                  id: item.id,
                  name: item.name,
                  type: "weekly",
                  start: tuesday.toDate(),
                  end: tuesday.toDate(),
                  color: "green",
                  timed: true,
                });
                startDay += 4;
                startSet.add(7, "d");
              }
            }

            if (item.type === "monthly") {
              events.push({
                id: item.id,
                name: item.name,
                type: "monthly",
                start: new Date(
                  currentYear + "-" + currentMonth + "-" + item.value
                ),
                end: new Date(
                  currentYear + "-" + currentMonth + "-" + item.value
                ),
                color: "indigo",
                timed: true,
              });
            }

            if (item.type === "yearly") {
              events.push({
                id: item.id,
                name: item.name,
                type: "yearly",
                start: new Date(currentYear + "-" + item.value),
                end: new Date(currentYear + "-" + item.value),
                color: "cyan",
                timed: true,
              });
            }

            if (item.type === "specify") {
              events.push({
                id: item.id,
                name: item.name,
                type: "specify",
                start: new Date(item.value),
                end: new Date(item.value),
                color: "orange",
                timed: true,
              });
            }
          });
          this.events = events;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  created() { },
};
</script>

<style scoped lang="scss">
.section--left-calendar {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .group-btn {
    width: 100px;
    height: 100%;
    margin-left: 140px;
    display: flex;
    box-shadow: 0 2px 4px 1px rgb(0 0 0 / 8%);
    border-radius: 4px;

    .button-left {
      width: 50px;
      height: 100%;
      border-radius: 3px 0 0 3px;
      box-shadow: 57px 15px 66px -19px rgba(227, 227, 227, 0.89);
      transition: 0.4s;

      i {
        font-size: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $main-btn-color;
      }
    }

    .button-right {
      transition: 0.4s;
      width: 50px;
      height: 100%;
      border-radius: 0 3px 3px 0;
      box-shadow: 57px 15px 66px -19px rgba(227, 227, 227, 0.89);
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $main-btn-color;
      }
    }

    .button-left:hover {
      transform: translateY(-3%);
      box-shadow: 0 2px 4px 1px rgb(0 0 0 / 8%);
      z-index: 2;
    }

    .button-right:hover {
      transform: translateY(-3%);
      background-color: white;
      box-shadow: 0 2px 4px 1px rgb(0 0 0 / 8%);
      z-index: 2;
    }
  }

  .show--current--year {
    position: absolute;
    width: 200px;
    font-size: 18px;
    font-weight: bold;
    font-family: $font-family;
    text-transform: capitalize;
    color: #4a4a4a;
    display: flex;
    right: 450px;
  }
}

.btn-active-today {
  width: 80px;
  height: 40px;
  background-color: $main-btn-color !important;
  color: #ffffff !important;
  box-shadow: 0 2px 4px 1px rgb(0 0 0 / 12%);
  margin-left: 25px;
  border-radius: 4px;
  text-align: center;
  transition: ease-out 0.2s;
}

.btn-active-today:hover {
  width: 80px;
  height: 40px;
  background-color: $main-btn-color !important;
  color: #ffffff !important;
  box-shadow: 0 2px 4px 1px rgb(0 0 0 / 12%);
  margin-left: 25px;
  border-radius: 4px;
  text-align: center;
  transform: translateY(-8%);
}

.holiday-detail {
  font-size: 12px;
}
</style>
