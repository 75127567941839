<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("adjustment.caption") }}</h1>
      </div>
    </div>
    <hr />
    <br />
    <div class="section-action">
      <v-row>
        <v-col cols="12" md="3" lg="3">
          <label class="label-input">{{ $t("ScanPoint.search_title") }}</label>
          <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense
            prepend-inner-icon="mdi-magnify" :placeholder="$t('Search.search')" v-model="searchItem"
            @keypress.enter="fetchAdjustment">
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <label class="label-input">{{ $t("ScanPoint.dept") }}</label>
          <v-select class="btn btn-color btn-border btn-border-color" outlined dense placeholder="..." hide-details
            :items="listDepartment" item-value="id" item-text="name" v-model="department_id" @change="fetchAdjustment">
          </v-select>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <label class="label-input">{{ $t("ScanPoint.branch") }}</label>

          <v-select class="btn btn-color btn-border btn-border-color" outlined dense placeholder="..." hide-details
            :items="listBranch" item-value="id" item-text="branch_name" v-model="branch_id" @change="fetchAdjustment">
          </v-select>
        </v-col>
      </v-row>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listAdjustment.length > 0">
          <thead>
            <tr>
              <th class="text-left">No</th>
              <th class="text-left">{{ $t("adjustment.table.emp_number") }}</th>
              <th class="text-left">{{ $t("adjustment.table.emp_name") }}</th>
              <th class="text-left">{{ $t("adjustment.table.dept") }}</th>
              <th class="text-left">
                {{ $t("adjustment.table.adjust") }}
              </th>

              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in listAdjustment" :key="idx">
              <td>{{ pagination.current_page * 10 - 10 + idx + 1 }}</td>
              <td>
                {{ item.emp_number }}
              </td>
              <td>{{ item.name }} {{ item.surname }}</td>
              <td>
                {{ item.department.name }}
              </td>
              <td style="display: flex; flex-direction: row;">
                <div
                  style="display: flex;flex-direction: column; border: 1px solid #B7B7B7;align-items: center;border-radius: 0px;">
                  <v-chip small style="background-color: #6A9AB0;color: #000;border-radius: 0 !important;">
                    <v-icon style="color: #000;margin-right: 5px;" size="15">mdi-calendar</v-icon>
                    {{ item.base_salary.lasted.effective_date }}
                  </v-chip>
                  <span> {{ $helpers.numberFormatter(item.base_salary.lasted.base_salary) }}</span>
                </div>
                <!-- <br /> -->
                <v-icon>mdi-arrow-right</v-icon>

                <div
                  style="display: flex;flex-direction: column; border: 1px solid #B7B7B7;align-items: center;border-radius: 0px;">
                  <v-chip small style="background-color:#3A6D8C !important;color: #fff;width: 100%;border-radius: 0 !important;">
                    <v-icon style="color: #fff;margin-right: 5px;" size="15">mdi-calendar</v-icon>
                    {{
                      item.base_salary.adjust.effective_date
                    }}</v-chip>
                  <span style="color:#2E86C1;margin: 0 5px;">
                    {{ $helpers.numberFormatter(item.base_salary.adjust.base_salary) }}
                    <v-btn class="ml-1" icon x-small color="error" @click="DeletedHistory(item.base_salary.adjust.id)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </span>

                </div>
              </td>
              <td>
                <v-btn fab dark x-small @click="showHistory(item.id)">
                  <v-icon dark> mdi-eye </v-icon>
                </v-btn>
              </td>
              <td>
                <v-btn class="mx-2" fab dark small color="primary" @click="
                  modalAdd(item.id, item.base_salary.adjust.base_salary)
                  ">
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else />

        <Pagination class="mt-5 mb-5" v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset"
          @paginate="fetchAdjustment">
        </Pagination>
        <Loading v-if="isLoading" />

        <!-- <showHistory
          :history="history"
          @close="close"
          @success="fetchAdjustment"
        /> -->

        <modalAdd :dialog="dialog" :employee_id="employee_id" :current_salary="current_salary" @close="dialog = false"
          @success="fetchAdjustment()" />
        <ModalEdit title="History Adjustment">
          <template v-slot="{ close }">
            <showHistory :history="history" @close="close" @delete="DeletedHistory" @edit="editSalaryAdjustment" />
          </template>
        </ModalEdit>
        <ModalDelete>
          <template v-slot="{ close }">
            <deleteHistory :history_id="history_id" @close="close" @success="fetchAdjustment()" />
          </template>
        </ModalDelete>
        <ModalEditSalary :editData="editData" :dialog="dialogEdit" @close="closeDialogEdit" @start="isLoading = true;"
          @success="fetchAdjustment" />
      </div>
    </div>


  </div>
</template>

<script>
import Pagination from "../../../components/Paginate/Pagination";
import Loading from "../../../components/Loading";
import defaultTableNoResult from "../../../components/defaultTableNoResult";
import modalAdd from "@/views/MainCompany/Adjustment/Modal/ModalAdd";
import showHistory from "@/views/MainCompany/Adjustment/Modal/ShowHistory";
import deleteHistory from "@/views/MainCompany/Adjustment/Modal/ModalDelete";
import ModalEditSalary from "@/views/MainCompany/Adjustment/Modal/ModalEdit";

export default {
  components: {
    Pagination,
    Loading,
    defaultTableNoResult,
    modalAdd,
    showHistory,
    deleteHistory,
    ModalEditSalary,
  },
  data() {
    return {
      dialogEdit: false,
      employeeScanPoint: [],
      employee: "",
      dialog: false,
      dialogR: false,
      history: {},
      model: null,
      offset: 10,
      pagination: {},
      per_page: 10,

      isLoading: false,
      listAdjustment: [],
      department: {},
      departmentId: "",
      searchItem: "",
      listDepartment: [],
      department_id: "",
      listBranch: [],
      branch_id: "",
      history_id: "",

      employee_id: "",
      current_salary: "",
      editData: {
        id: "",
        effective_date: "",
        base_salary: ""
      }
    };
  },
  methods: {
    closeDialogEdit(param) {
      this.dialogEdit = param;
    },
    filterDetail(id) {
      return (
        this.listAdjustment.filter((item) => {
          return item.id == id;
        })[0] || {}
      );
    },
    showHistory(id) {
      this.history = {
        ...this.filterDetail(id),
      };
      this.$store.commit("modalEdit_State", true);
    },
    modalAdd(employee_id, current_salary) {
      this.employee_id = employee_id;
      this.current_salary = current_salary;
      this.dialog = true;
    },

    DeletedHistory(id) {
      this.history_id = id;
      this.$store.commit("modalDelete_State", true);
    },

    editSalaryAdjustment(param) {
      this.editData.id = param.id;
      this.editData.effective_date = param.effective_date;
      this.editData.base_salary = param.base_salary;
      this.dialogEdit = true;
    },

    searchFilterItem() {
      this.fetchAdjustment();
    },
    filterScanPoint(id) {
      return (
        this.listAdjustment.filter((item) => {
          return item.id == id;
        })[0] || {}
      );
    },

    fetchAdjustment() {
      this.isLoading = true;
      this.$axios
        .get(`company/adjustment/salary/list`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            filter: this.searchItem,
            department_id: this.department_id,
            branch_id: this.branch_id,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.listAdjustment = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.listAdjustment.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    fetchDepartment() {
      this.$axios
        .get(`company/list/departments/selected`, {})
        .then((res) => {
          if (res.status === 200) {
            this.listDepartment = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchBranch() {
      this.$axios
        .get(`company/list/company/branches`)
        .then((res) => {
          if (res) {
            this.listBranch = res.data.companyBranches;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.fetchAdjustment();
    this.fetchDepartment();
    this.fetchBranch();
  },
};
</script>

<style scoped lang="scss">
.scan-point {
  background: rgb(68, 135, 241) !important;
  color: white !important;
  margin: 4px;
  padding: 5px;
  font-size: 12px;
  border-radius: 25px;

  a {
    color: rgb(216, 49, 49);
  }
}
</style>
