<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("vacation.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
          :placeholder="$t('Search.search')" v-model="searchItem" @keypress.enter="searchFilterItem">
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create lfont" @click="onCreate()">
          {{ $t("vacation.create") }}
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <!-- <v-btn class="btn-create lfont" @click="onCreate()">
          {{ $t("vacation.create") }}
        </v-btn> -->
        <table class="table table-view" v-if="listVacation.length > 0">
          <thead>
            <tr>
              <th class="text-start">
                {{ $t("vacation.no") }}
              </th>
              <th class="text-left">
                {{ $t("vacation.name") }}
              </th>
              <th class="text-left">
                {{ $t("vacation.category vacation") }}
              </th>
              <th class="text-left">
                {{ $t("vacation.amount") }}
              </th>
              <th class="text-left">
                {{ $t("vacation.month_can_use") }}
              </th>
              <th>{{ $t("vacation.using") }}</th>

              <th class="text-left">
                {{ $t("vacation.created") }}
              </th>
              <th class="text-left">
                {{ $t("vacation.updated") }}
              </th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in listVacation" :key="idx">
              <td>
                {{ pagination.current_page * 10 - 10 + idx + 1 }}
              </td>
              <td>
                {{ item.name }}
              </td>
              <td>
                {{ item.category_holiday.name }}
              </td>
              <td>
                {{ item.amount }}
              </td>
              <td>
                {{ item.month_can_use == 0 ? "" : item.month_can_use }}
              </td>
              <td>{{ item.status }}</td>

              <td>
                {{ item.created_at }}
              </td>
              <td>
                {{ item.updated_at }}
              </td>

              <td class="text-end">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined v-bind="attrs" v-on="on">
                      <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title class="btn-edit" @click="onEdit(item.id)">Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="btn-delete" @click="onDeleted(item.id)">Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else />
        <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset"
          @paginate="fetchVacation">
        </Pagination>

        <ModalEdit fullscreen title="ແກ້ໄຂຂໍ້ມູນ">
          <template v-slot="{ close }">
            <Edit :vacation="vacation" :amount_by_dates="amount_by_dates" @close="close" @success="fetchVacation"
              @update:amount_by_dates="(val) => (amount_by_dates = val)" />
          </template>
        </ModalEdit>

        <ModalDelete>
          <template v-slot="{ close }">
            <Delete :vacationId="vacationId" @close="close" @success="fetchVacation" />
          </template>
        </ModalDelete>
        <Loading v-if="isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../../components/Paginate/Pagination";
import defaultTableNoResult from "../../../components/defaultTableNoResult";
import Loading from "../../../components/Loading";
import Edit from "@/views/MainCompany/Vacation/form/Edit";
import Delete from "@/views/MainCompany/Vacation/form/Delete";

export default {
  components: {
    Loading,
    Pagination,
    defaultTableNoResult,
    Edit,
    Delete,
  },

  data() {
    return {
      offset: 10,
      pagination: {},
      per_page: 10,
      isLoading: false,
      searchItem: "",
      amount_by_dates: false,
      listVacation: [],
      vacation: {
        company_holiday_date: []
      },
      vacationId: "",
    };
  },
  methods: {
    searchFilterItem() {
      this.fetchVacation();
    },
    onCreate() {
      this.$router.push({ name: "vacation.create" }).catch(() => { });
    },

    filterVacation(vacationId) {
      return (
        this.listVacation.filter((item) => {
          return item.id == vacationId;
        })[0] || {}
      );
    },

    onEdit(vacationId) {
      this.vacation = {
        ...this.filterVacation(vacationId),
      };
      this.amount_by_dates = this.vacation.company_holiday_date.length > 0 ? true : false;
      this.$store.commit("modalEdit_State", true);
    },

    onDeleted(vacationId) {
      this.vacationId = vacationId;
      this.$store.commit("modalDelete_State", true);
    },

    fetchVacation() {
      this.isLoading = true;
      this.$axios
        .get(`company/list/holidays`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            filter: this.searchItem,
          },
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.isLoading = false;
            this.listVacation = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.listVacation.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchVacation();
  },
};
</script>

<style scoped lang="scss">
.btn-create {
  // background-color: rgb(151, 207, 255) !important;
  color: white !important;
}
</style>
