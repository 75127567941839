<template>
  <div class="payrolls">
    <div class="payrolls-header">
      <h2 class="text-h4"><span class="lfont"><i class="far fa-calculator" style="font-size: 1.5rem"></i>
          {{ $t('payroll.caculate_payroll.title') }}</span></h2>
    </div>
    <v-card class="payrolls-header">
      <div class="payrolls-left lfont">
        <v-form ref="form">

          <v-row>
            <v-col cols="12" md="12" lg="12">

              <div class="payroll-mode lfont">
                <v-row>
                  <v-col cols="12" md="12" sm="12" style="padding: 10px 0;">
                    <p class="text-h5 text-primary text-center"><span class="lfont">{{ calculate_title }}</span></p>
                  </v-col>
                  <v-col cols="12" md="2">
                    <label>{{ $t("payrollItem.mode2.dayWorkOfMonth") }}</label>
                    <v-text-field v-model="dayOfMonth" solo :label="$t('payrollItem.mode2.day')" clearable
                      readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <label>{{ $t("payrollItem.mode2.hourWorkOfDay") }}</label>
                    <v-text-field v-model="workTimeOfDay" solo :label="$t('payrollItem.mode2.hour')"
                      clearable></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <label>{{ $t("payrollItem.mode2.allHourOfMonth") }}</label>
                    <v-text-field v-model="hourWorkOfMonth" solo :label="$t('payrollItem.mode2.hour')"
                      readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <div class="input-checkbox">
                      <div class="checkbox">
                        <input type="checkbox" v-model="cutting_absence" />
                      </div>
                      <label>{{ $t("payrollItem.mode2.cutAbsenceMoney") }} (%)</label>
                    </div>
                    <v-text-field v-model="percen_cut_absence" solo label="%" clearable></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <div class="input-checkbox" style="white-space: nowrap" >
                      <div class="checkbox">
                        <input type="checkbox" v-model="cutting_late" />
                      </div>
                      <label >{{ $t("payrollItem.mode2.cutLateMoney") }} (%)</label>
                    </div>
                    <v-text-field v-model="percen_cut_late" solo label="%" clearable></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <div class="input-checkbox">
                      <div class="checkbox">
                        <input type="checkbox" v-model="cutting_leave_early" />
                      </div>
                      <label>{{ $t("payrollItem.mode2.cutLeaveEarlyMoney") }} (%)</label>
                    </div>
                    <v-text-field v-model="percen_cut_early" solo label="%" clearable></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <hr />
              <div style="display: flex; align-items: center;margin-bottom: 10px;" v-if="approved_status == 'pending'">

                <v-btn outlined class="btn-save-change mr-2" @click="addEmployee()">
                  <span><i class="fal fa-users"></i></span>
                </v-btn>

                <v-btn outlined class="btn-save-change mr-2" @click="importPayroll()">
                  <span><i class="fal fa-file-import"></i> Import</span>
                </v-btn>

                <v-btn class="btn-save-change mr-2" @click="calculateSalary">
                  <span><i class="fas fa-dollar-sign"></i>{{ $t("payrollItem.calculate Salary") }}</span>
                </v-btn>

                <v-checkbox v-model="reset_calculate" :label="`Reset Calculate`"></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card><br>
    <v-row v-if="employeeInPayroll.length > 0">
      <v-col cols="12">

        <v-btn outlined id="approve" class="btn-save-change mb-1 ml-4" @click="approveSlip"
          v-if="employeeInPayroll.length >= 0">
          <span><i class="fas fa-check"></i></span>{{ $t("payrollItem.approve") }}
        </v-btn>
        <v-btn outlined id="approve" class="btn-save-change mb-1 ml-4" @click="exportPayroll">
          <span><i class="fal fa-file-spreadsheet"></i></span> Export
          Excel
        </v-btn>
        <v-btn outlined id="approve" class="btn-save-change mb-1 ml-4" @click="exportPayrollToPdf">
          <span><i class="fal fa-file-spreadsheet"></i></span> Export PDF
        </v-btn>
      </v-col>
    </v-row>

    <v-card class="payrolls-content lfont" style="overflow-y: auto; font-size: 14px;"
      v-if="employeeInPayroll.length >= 0">
      <table class="table table-view table-salary ">
        <thead class="table-salary">
          <tr style="height: 60px">
            <td colspan="3" style="background-color: #f0f0f0" class="fixed-column"></td>
            <!-- <td class="text-center income" colspan="2" style="background:#E4B52E">{{ $t("payrollItem.conclude_absence_late") }}</td> -->
            <td class="text-center income" :colspan="7">
              {{ $t("payrollItem.income") }}
            </td>
            <td class="text-center deduction" :colspan="14">
              {{ $t("payrollItem.deduction") }}
            </td>
            <td class="text-center label-input" style="padding-top: 20px" colspan="4"></td>
          </tr>
          <tr>
            <th style="white-space: nowrap;" class="text-left th-salary fixed-column">
              <!-- <div style="border: 2px solid > -->
                {{ $t("payrollItem.image") }}
              <!-- </div> -->
            </th>
            <th style="white-space: nowrap" class="text-left th-salary fixed-column">
              {{ $t("payrollItem.emp_number") }}
            </th>
            <th style="white-space: nowrap" class="text-left th-salary fixed-column">
              {{ $t("payrollItem.name") }}
            </th>
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.effective_date") }}
            </th>
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.baseSalary") }}
            </th>
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.subTotal") }}
            </th>
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.salary") }}/{{ $t("payrollItem.hour") }}
            </th>
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.ot") }}({{ $t("payrollItem.hour") }})
            </th>
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.amount") }} {{ $t("payrollItem.ot") }}
            </th>
            <!-- <th style="white-space: nowrap" class="text-left th-salary">
                ເງີນເດືອນທີ່ຈະໄດ້ຮັບ
              </th> -->
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.otherIncome") }}
            </th>
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.totalInCome") }}
            </th>
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.absence") }}({{ $t("payrollItem.hour") }})
            </th>
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.amount") }} {{ $t("payrollItem.absence") }}/{{ $t("payrollItem.hour") }}
            </th>
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.amount") }} {{ $t("payrollItem.absence") }}
            </th>
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.late") }} ({{ $t("payrollItem.hour") }})
            </th>
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.amount") }} {{ $t("payrollItem.late") }}/{{ $t("payrollItem.hour") }}
            </th>
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.amount") }} {{ $t("payrollItem.late") }}
            </th>
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.early") }} ({{ $t("payrollItem.hour") }})
            </th>
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.amount") }} {{ $t("payrollItem.early") }}/{{ $t("payrollItem.hour") }}
            </th>
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.amount") }} {{ $t("payrollItem.early") }}
            </th>
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.other Deduction") }}
            </th>
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.income_before_SSO") }}
            </th>
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.sso") }}
            </th>
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.income_before_TAX") }}
            </th>
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.tax") }}
            </th>
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.total") }}
            </th>
            <th style="white-space: nowrap" class="text-left th-salary">
              {{ $t("payrollItem.status") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in employeeInPayroll" :key="index">
            <td class="fixed-column">
              <div v-if="item.profile" class="img-table-company">
                <img src="" alt="" />
              </div>
              <div v-else class="demo-profile">
                <i class="fal fa-user-alt"></i>
              </div>
            </td>
            <td style="white-space: nowrap" class="fixed-column">
              {{ item.emp_number }}
            </td>
            <td style="white-space: nowrap" v-if="item.emp_name" class="fixed-column">
              {{ item.emp_name }} <span>{{ item.emp_surname }}</span>
            </td>
            <td class="text-income" v-if="item.adjustment" style="white-space: nowrap">
              <p v-for="(adjustment_item, index) in item.adjustment" class="text-danger">
                {{ adjustment_item.effective_date }}
              </p>
            </td>
            <td v-else></td>
            <td class="text-income" v-if="item.adjustment">
              <p v-for="(adjustment_item, index) in item.adjustment">
                {{
                  $helpers.numberFormatter(
                    $helpers.numberFormatterDecimal(adjustment_item.base_salary)
                  )
                }}
              </p>
            </td>
            <td class="text-income" v-else>
              {{
                $helpers.numberFormatter(
                  $helpers.numberFormatterDecimal(item.base_salary)
                )
              }}
            </td>


            <td class="text-income" v-if="item.adjustment">
              <p v-for="(adjustment_item, index) in item.adjustment">
                {{
                  $helpers.numberFormatter(
                    $helpers.numberFormatterDecimal(adjustment_item.sub_total)
                  )
                }}
              </p>
            </td>
            <td class="text-income" v-else>
              {{
                $helpers.numberFormatter(
                  $helpers.numberFormatterDecimal(item.sub_total)
                )
              }}
            </td>

            <td class="text-income" v-if="item.adjustment">
              <p v-for="(adjustment_item, index) in item.adjustment">
                {{
                  $helpers.numberFormatter(
                    $helpers.numberFormatterDecimal(adjustment_item.salary_per_hour)
                  )
                }}
              </p>
            </td>
            <td class="text-income" v-else>
              {{
                $helpers.numberFormatter(
                  $helpers.numberFormatterDecimal(item.salary_per_hour)
                )
              }}
            </td>

            <td class="text-income" v-if="item.adjustment">
              <p v-for="(adjustment_item, index) in item.adjustment">
                <span>{{ convertHours(adjustment_item.qty_hour_ot) }}h {{
                  convertMinutes(adjustment_item.qty_hour_ot) }} m</span>
              </p>
            </td>
            <td class="text-income" v-else>
              <span>{{ convertHours(item.qty_hour_ot) }}h {{
                convertMinutes(item.qty_hour_ot) }} m</span>
            </td>

            <td class="text-income" v-if="item.adjustment">
              <p v-for="(adjustment_item, index) in item.adjustment">
                {{
                  $helpers.numberFormatter(
                    $helpers.numberFormatterDecimal(adjustment_item.money_ot)
                  )
                }}
              </p>
            </td>
            <td class="text-income" v-else>
              {{
                $helpers.numberFormatter(
                  $helpers.numberFormatterDecimal(item.money_ot)
                )
              }}
            </td>

            <td class="text-income" style="white-space: nowrap">
              <v-menu transition="slide-y-transition" bottom
                v-if="item.status === 'processing' || item.status === 'pending'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="green" @click="addOtheIncome(item.id, item.emp_name.id)" v-bind="attrs" v-on="on">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
              </v-menu>
              <div v-for="(benefit, index) in item.payrollBenefits" :key="index" class="d-flex flex-column">
                <ul style="margin: 0; padding: 0">
                  <li @click="updateBenefit(benefit, item.id, item.status)" class="benefit-item">
                    <span class="name">
                      {{ index + 1 }}.{{ benefit.name }}</span>
                    ( {{ $helpers.numberFormatter(benefit.value) }} )
                  </li>
                </ul>
              </div>
            </td>

            <td class="text-income">
              <span>
                {{
                  $helpers.numberFormatter($helpers.numberFormatterDecimal(item.net_income))
                }}
              </span>
            </td>

            <td class="text-deduction" v-if="item.adjustment">
              <p v-for="(adjustment_item, index) in item.adjustment">
                <span>{{ convertHours(adjustment_item.absence_minute) }}h {{
                  convertMinutes(adjustment_item.absence_minute) }}m</span>
                <span
                  @click="updateTime(item.id, 'absence_minute', adjustment_item.absence_minute, adjustment_item.id, item.status)"
                  class="ml-2">
                  <v-btn icon x-small color="indigo">
                    <v-icon>mdi-note-edit-outline</v-icon>
                  </v-btn></span>
              </p>

            </td>
            <td class="text-deduction" v-else>
              <span>{{ convertHours(item.absence_minute) }}h {{ convertMinutes(item.absence_minute) }}m</span>
              <span @click="updateTime(item.id, 'absence_minute', item.absence_minute, '')" class="ml-2">
                <v-btn icon x-small color="indigo">
                  <v-icon>mdi-note-edit-outline</v-icon>
                </v-btn></span>
            </td>


            <td class="text-deduction" v-if="item.adjustment">
              <p v-for="(adjustment_item, i) in item.adjustment">
                <span>{{
                  $helpers.numberFormatter($helpers.numberFormatterDecimal(adjustment_item.cost_absence_per_minute
                    * 60)) }}
                  ({{ adjustment_item.percen_cut_absence }}%)</span>
                <span
                  @click="updateData(item.id, 'cost_absence_per_minute', adjustment_item.cost_absence_per_minute * 60, adjustment_item.id, item.status)"
                  class="ml-2">
                  <v-btn icon x-small color="indigo">
                    <v-icon>mdi-note-edit-outline</v-icon>
                  </v-btn></span>
              </p>
            </td>
            <td class="text-deduction" v-else>
              <span>
                <span>{{ $helpers.numberFormatter($helpers.numberFormatterDecimal(item.cost_absence_per_minute * 60)) }}
                  ({{ item.percen_cut_absence }}%)</span>
                <span @click="updateData(item.id, 'cost_absence_per_minute', item.cost_absence_per_minute * 60, '')"
                  class="ml-2">
                  <v-btn icon x-small color="indigo">
                    <v-icon>mdi-note-edit-outline</v-icon>
                  </v-btn></span>
              </span>
            </td>


            <td class="text-deduction" v-if="item.adjustment">
              <p v-for="(adjustment_item, i) in item.adjustment">
                <span>{{
                  $helpers.numberFormatter(
                    $helpers.numberFormatterDecimal(adjustment_item.absence_amount_minute)
                  )
                }}</span>
              </p>

            </td>
            <td class="text-deduction" v-else>
              <span>
                <span>{{
                  $helpers.numberFormatter(
                    $helpers.numberFormatterDecimal(item.absence_amount_minute)
                  )
                }}</span>
              </span>

            </td>

            <td class="text-deduction" v-if="item.adjustment">
              <p v-for="(adjustment_item, i) in item.adjustment">
                <span>{{ convertHours(adjustment_item.late_per_minutes) }}h {{
                  convertMinutes(adjustment_item.late_per_minutes) }} m</span>
                <span
                  @click="updateTime(item.id, 'late_per_minutes', adjustment_item.late_per_minutes, adjustment_item.id, item.status)"
                  class="ml-2">
                  <v-btn icon x-small color="indigo">
                    <v-icon>mdi-note-edit-outline</v-icon>
                  </v-btn></span>
              </p>

            </td>
            <td class="text-deduction" v-else>
              <span>{{ convertHours(item.late_per_minutes) }} ຊົ່ວໂມງ</span><br>
              <span>{{ convertMinutes(item.late_per_minutes) }} ນາທີ</span>
              <span @click="updateTime(item.id, 'late_per_minutes', item.late_per_minutes, '')" class="ml-2">
                <v-btn icon x-small color="indigo">
                  <v-icon>mdi-note-edit-outline</v-icon>
                </v-btn></span>
            </td>

            <td class="text-deduction" v-if="item.adjustment">
              <p v-for="(adjustment_item, i) in item.adjustment">
                <span>{{ $helpers.numberFormatter(
                  $helpers.numberFormatterDecimal(adjustment_item.cost_late_per_minute * 60)
                ) }} ({{ adjustment_item.percen_cut_late }}%)</span>
                <span
                  @click="updateData(item.id, 'cost_late_per_minute', adjustment_item.cost_late_per_minute * 60, adjustment_item.id, item.status)"
                  class="ml-2">
                  <v-btn icon x-small color="indigo">
                    <v-icon>mdi-note-edit-outline</v-icon>
                  </v-btn>
                </span>
              </p>

            </td>
            <td class="text-deduction" v-else>
              <span>
                <span>{{ $helpers.numberFormatter(
                  $helpers.numberFormatterDecimal(item.cost_late_per_minute * 60)
                ) }} ({{ item.percen_cut_late }}%)</span>
                <span
                  @click="updateData(item.id, 'cost_late_per_minute', item.cost_late_per_minute * 60, '', item.status)"
                  class="ml-2">
                  <v-btn icon x-small color="indigo">
                    <v-icon>mdi-note-edit-outline</v-icon>
                  </v-btn>
                </span>
              </span>

            </td>

            <td class="text-deduction" v-if="item.adjustment">
              <p v-for="(adjustment_item, i) in item.adjustment">
                <span>{{ $helpers.numberFormatter(
                  $helpers.numberFormatterDecimal(adjustment_item.late)
                ) }}</span>
              </p>

            </td>
            <td class="text-deduction" v-else>
              <span>
                <span>{{ $helpers.numberFormatter(
                  $helpers.numberFormatterDecimal(item.late)
                ) }}</span>
              </span>

            </td>

            <td class="text-deduction" v-if="item.adjustment">
              <p v-for="(adjustment_item, i) in item.adjustment">
                <span>{{ convertHours(adjustment_item.leave_early_per_minute) }}h {{
                  convertMinutes(adjustment_item.leave_early_per_minute) }}m</span>
                <span
                  @click="updateTime(item.id, 'leave_early_per_minute', adjustment_item.leave_early_per_minute, adjustment_item.id, item.status)"
                  class="ml-2">
                  <v-btn icon x-small color="indigo">
                    <v-icon>mdi-note-edit-outline</v-icon>
                  </v-btn>
                </span>
              </p>
            </td>
            <td class="text-deduction" v-else>
              <span>{{ convertHours(item.leave_early_per_minute) }} ຊົ່ວໂມງ</span><br>
              <span>{{ convertMinutes(item.leave_early_per_minute) }} ນາທີ</span>
              <span @click="updateTime(item.id, 'leave_early_per_minute', item.leave_early_per_minute, '', item.status)"
                class="ml-2">
                <v-btn icon x-small color="indigo">
                  <v-icon>mdi-note-edit-outline</v-icon>
                </v-btn>
              </span>
            </td>

            <td class="text-deduction" v-if="item.adjustment">
              <p v-for="(adjustment_item, i) in item.adjustment">
                <span>{{ $helpers.numberFormatter(
                  $helpers.numberFormatterDecimal(adjustment_item.cost_leave_early * 60)
                ) }} ({{ adjustment_item.percen_cut_early }}%)</span>
                <span
                  @click="updateData(item.id, 'cost_leave_early', adjustment_item.cost_leave_early * 60, adjustment_item.id, item.status)"
                  class="ml-2">
                  <v-btn icon x-small color="indigo">
                    <v-icon>mdi-note-edit-outline</v-icon>
                  </v-btn>
                </span>
              </p>

            </td>
            <td class="text-deduction" v-else>
              <span>{{ $helpers.numberFormatter(
                $helpers.numberFormatterDecimal(item.cost_leave_early * 60)
              ) }} ({{ item.percen_cut_early }}%)</span>
              <span @click="updateData(item.id, 'cost_leave_early', item.cost_leave_early * 60, '', item.status)"
                class="ml-2">
                <v-btn icon x-small color="indigo">
                  <v-icon>mdi-note-edit-outline</v-icon>
                </v-btn>
              </span>
            </td>

            <td class="text-deduction" v-if="item.adjustment">
              <p v-for="(adjustment_item, i) in item.adjustment">
                {{ $helpers.numberFormatter(
                  $helpers.numberFormatterDecimal(adjustment_item.leave_early)
                ) }} </p>
            </td>
            <td class="text-deduction" v-else>
              <span>{{ $helpers.numberFormatter(
                $helpers.numberFormatterDecimal(item.leave_early)
              ) }} </span>
            </td>

            <td class="text-deduction" style="white-space: nowrap">
              <v-menu transition="slide-y-transition" bottom
                v-if="item.status === 'processing' || item.status === 'pending'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="green" @click="addDeductions(item.id)" v-bind="attrs" v-on="on">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
              </v-menu>
              <div v-for="(deduction, index) in item.payrollDeductionItemDetails" :key="index"
                class="d-flex flex-column">
                <ul style="margin: 0; padding: 0">
                  <li style="cursor: pointer" class="benefit-item deduction-color"
                    @click="updateDeduction(deduction, item.id, item.status)">
                    <v-tooltip bottom color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <span :class="deduction.who_pay == 'employer' ? `text-success` : `text-danger`" v-bind="attrs"
                          v-on="on">
                          {{ index + 1 }}.{{ deduction.name }} <span>{{ deduction.percent ?
                            `(${deduction.percent} %) ` : ' ' }} </span>
                          {{ $helpers.numberFormatter(deduction.money) }}
                        </span>
                      </template>
                      <p class="lfont"><u>{{ $t("payrollAddDeduction.cut_out_deduction") }}</u></p>
                      <p class="lfont">1. {{ $t("payrollAddDeduction.deduction_title") }}</p>
                      <div v-if="deduction.cut_out_deduction != null">
                        <p v-for="(cut_out_deduction, k) in deduction.cut_out_deduction" class="lfont">
                          . {{ cut_out_deduction.name }} {{ $helpers.numberFormatter(cut_out_deduction.money) }}
                        </p>
                      </div>
                      <p v-else></p>

                      <p class="lfont">2. {{ $t("payrollAddDeduction.condition") }}</p>
                      <p v-if="deduction.condition"><span v-if="deduction.cut_out_condition">-</span>{{
                        $helpers.numberFormatter(deduction.condition) }}</p>
                    </v-tooltip>
                  </li>
                </ul>
              </div>
            </td>

            <td class="text-income">
              {{
                $helpers.numberFormatter(
                  $helpers.numberFormatterDecimal(item.all_total_before_sso)
                )
              }}
            </td>
            <td class="text-deduction">
              {{
                $helpers.numberFormatter(
                  $helpers.numberFormatterDecimal(item.sso)
                )
              }}
            </td>
            <td class="text-income">
              {{
                $helpers.numberFormatter(
                  $helpers.numberFormatterDecimal(item.all_total_before_tax)
                )
              }}
            </td>
            <td class="text-deduction">
              {{
                $helpers.numberFormatter(
                  $helpers.numberFormatterDecimal(item.tax)
                )
              }}
            </td>
            <td class="text-income">
              <b>{{
                $helpers.numberFormatter(
                  $helpers.numberFormatterDecimal(item.total)
                )
              }}</b>
            </td>
            <td>
              {{ item.status }}
            </td>
            <td width="50">
              <v-btn icon color="pink" @click="deletePayroll(item.id)"
                v-if="item.status === 'processing' || item.status === 'pending'">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>


        </tbody>
      </table>

      <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset"
        @paginate="fetchEmployeeInPayrolls">
      </Pagination>
      <AddEmployee :visible="dialog_add_emp" :payroll_id="payroll_id" @success="fetchEmployeeInPayrolls"
        @change="(val) => (dialog_add_emp = val)" />
      <ImportPayrollData :visible="dialog_import_payroll" :payroll_id="payroll_id" @success="fetchEmployeeInPayrolls"
        @change="(val) => (dialog_import_payroll = val)" />
      <UpdatePayrollBenefit :visible="modalUpdatePayrollBenefit" @success="fetchEmployeeInPayrolls"
        :payroll_benefit="payroll_benefit" :payroll_emp_id="payroll_emp_id"
        @change="(val) => (modalUpdatePayrollBenefit = val)" />
      <UpdatePayrollData :visible="modalUpdatePayrollData" @success="fetchEmployeeInPayrolls" :editData="editData"
        @change="(val) => (modalUpdatePayrollData = val)" />
      <UpdatePayrollTime :visible="modalUpdatePayrollTime" @success="fetchEmployeeInPayrolls" :editTime="editTime"
        @change="(val) => (modalUpdatePayrollTime = val)" />
      <UpdateDeduction :visible="modalUpdateDeduction" @save="(val) => (isLoading = val)"
        @success="fetchEmployeeInPayrolls" :deductionItem="deductionItem" :payroll_emp_id="payroll_emp_id"
        @change="(val) => (modalUpdateDeduction = val)" />
      <CreateDeduction :visible="modalDeduction" :payroll_id="payroll_id" :payroll_emp_id="payroll_emp_id"
        @success="fetchEmployeeInPayrolls" @save="(val) => (isLoading = val)"
        @change="(val) => (modalDeduction = val)" />
      <CreateOtheIncome :visible="modalOtheIncome" :payroll_emp_id="payroll_emp_id" :emp_id="emp_id"
        @success="fetchEmployeeInPayrolls" @change="(val) => (modalOtheIncome = val)" />
      <ConfirmApprovePayslip :dialog="modalApprovePayroll" :payroll_id="payroll_id" @save="(val) => (isLoading = val)"
        @close="(val) => (modalApprovePayroll = val)" @success="fetchEmployeeInPayrolls" />
    </v-card>
    <v-card class="payrolls-content no-data" v-else>
      <h1>{{ $t("payroll.nodata") }}</h1>
      <img :src="require('@/assets/calendar.png')" alt="" />
    </v-card>
    <Loading v-if="isLoading" />
  </div>
</template>
<script>
import UpdatePayrollBenefit from "@/views/MainCompany/PayrollsItem/modal/UpdatePayrollBenefit";
import CreateDeduction from "@/views/MainCompany/PayrollsItem/modal/CreateDeduction";
import CreateOtheIncome from "@/views/MainCompany/PayrollsItem/modal/CreateOtheIncome";
import UpdateDeduction from "@/views/MainCompany/PayrollsItem/modal/UpdateDeduction";
import UpdatePayrollData from "@/views/MainCompany/PayrollsItem/modal/UpdatePayrollData";
import UpdatePayrollTime from "@/views/MainCompany/PayrollsItem/modal/UpdatePayrollTime";
import AddEmployee from "@/views/MainCompany/PayrollsItem/modal/AddEmployee";
import ImportPayrollData from "@/views/MainCompany/PayrollsItem/modal/ImportPayrollData";
import ConfirmApprovePayslip from "../modal/ConfirmApprovePayslip.vue";
import Pagination from "@/components/Paginate/Pagination";
import Loading from "@/components/Loading";

export default {
  components: {
    AddEmployee,
    UpdatePayrollBenefit,
    CreateDeduction,
    Loading,
    UpdateDeduction,
    CreateOtheIncome,
    UpdatePayrollData,
    ImportPayrollData,
    UpdatePayrollTime,
    ConfirmApprovePayslip,
    Pagination,
  },
  data() {
    return {
      offset: 10,
      pagination: {},
      per_page: 10,

      approved_status: "pending",
      calculate_title: "",
      reset_calculate: true,
      dialog_add_emp: false,
      dialog_import_payroll: false,
      totalQtyWrok: 260,
      dayOfMonth: this.$route.params.qty_day_work,
      workTimeOfDay: this.$route.params.qty_hour_work,
      percen_cut_absence: 100,
      percen_cut_late: 100,
      percen_cut_early: 100,
      mode1: true,
      mode2: false,
      isLoading: true,
      showBtnCalculateApprove: false,
      payroll_benefit: {},
      payroll_emp_id: "",
      modalUpdatePayrollBenefit: false,
      modalUpdatePayrollData: false,
      modalUpdatePayrollTime: false,
      modalApprovePayroll: false,
      editData: {
        emp_payroll_id: "",
        emp_payroll_adjustment_id: "",
        title: "",
        value: "",
      },
      editTime: {
        emp_payroll_id: "",
        emp_payroll_adjustment_id: "",
        title: "",
        hours: "",
        minutes: "",
      },
      modalUpdateDeduction: false,
      modalDeduction: false,
      modalOtheIncome: false,
      deductionItem: {},
      employeeInPayroll: [],
      payrollTotal: {},
      payroll_id: this.$route.params.payroll_id,
      data: {
        employee: [],
      },
      emp_id: [],
      caculate_mode: 1,
      cutting_late: true,
      cutting_absence: true,
      cutting_leave_early: true,
      server_errors: {
        cutting_late: "",
        cutting_absence: "",
        cutting_leave_early: "",
        percen_cut_late: "",
        percen_cut_early: "",
        percen_cut_absence: ""
      }
    };
  },
  computed: {
    hourWorkOfMonth() {
      return this.dayOfMonth * this.workTimeOfDay;
    },

    getUserPermission() {
      return JSON.parse(localStorage.getItem("userPermission"));
    },
  },
  methods: {
    check_adjustment(status) {
      return status.length;
    },
    convertHours(minutes) {
      let hours = Math.floor(minutes / 60);
      return hours;
    },
    convertMinutes(minutes) {
      let remainingMinutes = minutes % 60;
      return remainingMinutes;
    },

    deletePayroll(id) {
      this.isLoading = true;
      this.$axios
        .delete(`company/delete/payroll/employee/${id}`)
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.fetchEmployeeInPayrolls();
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
    exportPayroll() {
      this.isLoading = true;
      this.$axios
        .get(`export/payroll/employees/to/excel/${this.payroll_id}`, {
          responseType: "blob",
        })
        .then((res) => {
          const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileUrl;
          fileLink.setAttribute("download", "payroll.xlsx");
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    exportPayrollToPdf() {
      this.isLoading = true;
      this.$axios
        .get(`export/payroll/employees/to/pdf/${this.payroll_id}`, {
          responseType: "arraybuffer",
        })
        .then((res) => {
          this.isLoading = false;
          const blob = new Blob([res.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "payroll.pdf";
          link.click();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    calculateTotalIncome(baseSalary, totalIncome) {
      return baseSalary + totalIncome;
    },
    calculateAfterTaxAndSSO(baseSalary, totalIncome, sso, tax) {
      return baseSalary + totalIncome - sso - tax;
    },
    removeItemFromTable(item) {
      const index = this.data.employee.indexOf(item.id);
      if (index >= 0) {
        this.data.employee.splice(index, 1);
      }
    },
    fetchEmployeeInPayrolls() {
      this.isLoading = true;
      this.$axios
        .get(`company/list/payroll/employees/${this.payroll_id}`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
          },
        })
        .then((res) => {
          this.isLoading = false;
          if (res.status === 200) {
            this.employeeInPayroll = res.data.data.payrollEmployees.data;
            this.pagination = res.data.data.payrollEmployees.pagination;
            this.calculate_title = res.data.data.name;
            // check cutting late and absence
            if (this.employeeInPayroll.length > 0) {
              this.dayOfMonth = res.data.data.day_work_of_month;
              this.workTimeOfDay = res.data.data.hour_work_of_day;
              this.percen_cut_absence = res.data.data.percen_cut_absence;
              this.percen_cut_late = res.data.data.percen_cut_late;

              this.reset_calculate = res.data.data.reset_calculate;
              this.cutting_absence = res.data.data.cutting_absence;
              this.cutting_late = res.data.data.cutting_late;
              this.cutting_leave_early = res.data.data.cutting_leave_early;
              this.approved_status = res.data.data.status;

              if (!this.employeeInPayroll.length > 0) {
                this.pagination.current_page = this.pagination.current_page - 1;
              }
            }

            //this.payrollTotal = res.data.data.summary;
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    addEmployee() {
      this.dialog_add_emp = true;
    },
    importPayroll() {
      this.dialog_import_payroll = true;
    },
    updateTime(payroll_emp_id, title, value, emp_payroll_adjustment_id, status) {
      if (status == 'approved') return;
      this.modalUpdatePayrollTime = true;
      this.editTime.emp_payroll_id = payroll_emp_id;
      this.editTime.emp_payroll_adjustment_id = emp_payroll_adjustment_id;
      this.editTime.title = title;
      this.editTime.hours = this.convertHours(value);
      this.editTime.minutes = this.convertMinutes(value);
    },
    updateData(payroll_emp_id, title, value, emp_payroll_adjustment_id, status) {
      if (status == 'approved') return;
      this.modalUpdatePayrollData = true;
      this.editData.emp_payroll_id = payroll_emp_id;
      this.editData.emp_payroll_adjustment_id = emp_payroll_adjustment_id;
      this.editData.title = title;
      this.editData.value = value;
    },
    updateBenefit(benefit, payroll_emp_id, status) {
      if (status == 'approved') return;
      this.payroll_benefit = benefit;
      this.payroll_emp_id = payroll_emp_id;
      this.modalUpdatePayrollBenefit = true;
    },
    updateDeduction(deduction, payroll_emp_id, status) {
      if (status == 'approved') return;
      this.deductionItem = deduction;
      this.payroll_emp_id = payroll_emp_id;
      this.modalUpdateDeduction = true;
    },
    calculateSalary() {
      this.isLoading = true;
      this.$axios
        .post(`company/calculate/payroll/employees/${this.payroll_id}`, {
          qty_day_work: this.dayOfMonth,
          qty_hour_work: this.workTimeOfDay,
          reset_calculate: this.reset_calculate,
          cutting_late: this.cutting_late,
          cutting_absence: this.cutting_absence,
          cutting_leave_early: this.cutting_leave_early,
          percen_cut_late: this.percen_cut_late,
          percen_cut_early: this.percen_cut_early,
          percen_cut_absence: this.percen_cut_absence,
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.fetchEmployeeInPayrolls();
            this.showBtnCalculateApprove = true;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
    approveSlip() {
      this.modalApprovePayroll = true;
    },
    addDeductions(payroll_emp_id) {
      this.payroll_emp_id = payroll_emp_id;
      this.modalDeduction = true;
    },
    addOtheIncome(payroll_emp_id, emp_id) {
      this.payroll_emp_id = payroll_emp_id;
      this.emp_id = emp_id;
      this.modalOtheIncome = true;
    },
  },
  created() {
    this.fetchEmployeeInPayrolls();
  },
};
</script>

<style scoped lang="scss">
.input-checkbox {
  label {
    padding: 5px;
    margin-bottom: 5px;
  }

  .checkbox {
    padding-top: 5px;
  }
}

.payroll-mode {
  // padding-left: 30px;
}

.btn-search {
  color: #ffffff !important;
  height: 50px !important;
}

.payrolls {
  width: 100%;
  min-height: 100vh;

  background: #eeeeee;
  padding: 10px;
  border-radius: 0 !important;

  .payrolls-header {
    height: auto;
    width: 100%;
    display: flex;
    padding: 10px;

    .payrolls-left {
      flex: 1;
      padding: 30px 10px 0 10px;
    }

    .payrolls-right {
      flex: 1;
      margin-top: 70px;
    }
  }

  .payrolls-content {
    height: auto;
    margin-top: 20px;
    position: relative;
    padding: 20px 0px 40px 0px;
  }

  .no-data {
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      font-family: $font-family;
      font-size: 1.4rem;
      margin-bottom: 8px;
    }

    img {
      width: 200px;
      height: 200px;
    }
  }
}

.benefit-item {
  background: rgba(255, 255, 255, 0.6);
  margin: 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  color: #0065f3;
  font-family: $font-family;
  cursor: pointer;
  padding: 0 8px;

  .name {
    color: black;
  }
}

.deduction-color {
  background: rgba(255, 255, 255, 0.6);
  color: red;
  cursor: default;

  .name {
    color: black;
  }
}

.benefit-item:hover {
  background: #d5d6d9;
}

.fixed-column {
  position: sticky;
  left: -1px;
  // width: 1000px !important;
  background-color: #f0f0f0;
  // padding: 1px;
  // border: 1px solid red;
  /* Set background color for visibility */
  z-index: 2;
}

.fixed-column:nth-child(2) {
  left: 81px;
  // border: 2px solid red;
  /* Adjust based on column width */
}

.fixed-column:nth-child(3) {
  left: 187px;
  /* Adjust based on column width */
}


.btn-approved {
  background: #95df93 !important;
}

.table-total {
  background-color: #eeeeee;
}

.text-uppercase {
  font-weight: 600;
  font-size: 14px;
}

.conclude-color {
  color: #ebb20e;
}

.border-color {
  border-color: #ffffff !important;
}
</style>
