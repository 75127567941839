<template>
  <div>
    <div class="header-container">
      <v-breadcrumbs>
        <v-breadcrumbs-item class="breadcrumbs-hover-default">
          <i class="far fa-list-alt" style="margin: 0 10px 0 0px"></i>
          <span>{{ $t("taxReport.title") }}</span>
        </v-breadcrumbs-item>
      </v-breadcrumbs>
    </div>
    <div class=" page-content">
      <div class="content" style="padding: 20px;">
        <!-- <h2 style="margin-bottom:10px ;">
        <span><i class="far fa-list-alt"></i></span> {{ $t("taxReport.title") }}
      </h2> -->
        <v-sheet class="card-select-date" width="100%">
          <v-row dense>
            <v-col cols="3" md="3" lg="2">
              <label class="label-input">{{ $t("taxReport.startDate") }}</label>
              <DatePicker class="date-time" style="width: 100%" type="date" valueType="format" slot="activator"
                :append-to-body="true" name="founding_date" v-model="start_date" @change="handleEventFilterDate()">
              </DatePicker>
            </v-col>
            <v-col cols="3" md="3" lg="2">
              <label class="label-input">{{ $t("taxReport.endDate") }}</label>
              <DatePicker class="date-time" style="width: 100%" type="date" valueType="format" slot="activator"
                :append-to-body="true" v-model="end_date" @change="handleEventFilterDate()" name="founding_date">
              </DatePicker>
            </v-col>
            <v-col cols="6" md="6" lg="3">
              <label class="label-input">{{
                $t("taxReport.calcuSalaryItem")
                }}</label>
              <v-select outlined dense :items="listPayrolls" item-value="id" item-text="name" v-model="payroll_id">
              </v-select>
            </v-col>
            <v-col cols="3" md="3" lg="3" style="margin-top: 1.6rem;">
              <v-btn class="btn-action" @click="fetchPayrollsSso" style="margin-left: 0;">
                {{ $t("taxReport.search") }}
              </v-btn>
              <v-btn class="btn-action confirm" @click="exportExcel" v-if="taxPayroll.length > 0"
                style="margin-left: 0;">
                <span><i class="fal fa-file-spreadsheet"></i></span>
                {{ $t("taxReport.export") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <!-- <v-col cols="6" md="6" lg="6">
          <label class="label-input">{{
            $t("taxReport.calcuSalaryItem")
          }}</label>
          <v-select outlined dense :items="listPayrolls" item-value="id" item-text="name" v-model="payroll_id">
          </v-select>
        </v-col> -->

          </v-row>
        </v-sheet>
        <v-sheet  width="100%" height="auto" color="white">

        <!-- <v-sheet class="mt-4 pa-4" width="100%" height="auto" color="white"> -->
          <v-card class="payrolls-content" style="overflow-y: auto">
            <table class="table table-view table-salary" v-if="taxPayroll.length > 0">
              <tr>
                <th>No</th>
                <th>{{ $t("taxReport.empCode") }}</th>
                <th>{{ $t("taxReport.name") }}</th>
                <th>{{ $t("taxReport.gender") }}</th>
                <th>{{ $t("taxReport.position") }}</th>
                <th>{{ $t("taxReport.dept") }}</th>
                <th>{{ $t("taxReport.totalIncome") }}</th>
                <th v-for="(item, index) in taxText" :key="index">{{ item }} %</th>
                <th>{{ $t("taxReport.totalTaxPaid") }}</th>
              </tr>
              <tbody>
                <tr v-for="(item, index) in taxPayroll" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.emp_number }}</td>
                  <td>{{ item.name }} {{ item.surname }}</td>
                  <td>{{ item.gender }}</td>
                  <td>
                    <span v-if="item.position.length > 0">{{
                      item.position[0].name
                      }}</span>
                  </td>
                  <td>{{ item.department[0].name }}</td>
                  <td>{{ $helpers.numberFormatter(item.sub_total) }}</td>
                  <td v-for="(taxItem, key) in item.tax" :key="key">
                    {{ $helpers.numberFormatter(taxItem) }}
                  </td>
                  <td>{{ $helpers.numberFormatter(item.totalTax) }}</td>
                </tr>
              </tbody>
            </table>
          </v-card>
          <loading v-if="isLoading" />
          <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset"
            @paginate="fetchPayrollsSso">
          </Pagination>
        </v-sheet>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import Loading from "@/components/Loading";
import "vue2-datepicker/index.css";
import Pagination from "../../components/Paginate/Pagination";

export default {
  name: "TodoList",
  components: {
    DatePicker,
    Loading,
    Pagination,
  },
  data() {
    return {
      offset: 10,
      pagination: {},
      per_page: 10,
      isLoading: false,
      payroll_id: "",
      listPayrolls: [],

      start_date: new Date().toISOString().slice(0, 10),
      end_date: new Date().toISOString().slice(0, 10),
      taxPayroll: [],
      taxText: [],
    };
  },
  methods: {
    fetchPayrollsSso() {
      if (this.payroll_id == "") return;
      this.isLoading = true;
      this.$axios
        .post(`company/list/report/tax/payroll/${this.payroll_id}`, {
          per_page: this.per_page,
          page: this.pagination.current_page,
        })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            this.taxPayroll = res.data.data.data;
            this.pagination = res.data.data.paginate;
            this.taxText = res.data.data.taxText;
            if (!this.employees.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },

    handleEventFilterDate() {
      const items = {
        startDate: this.start_date,
        endDate: this.end_date,
      };
      this.fetchPayrolls(items);
    },

    fetchPayrolls(item) {
      this.isLoading = true;
      this.$axios
        .post(`company/list/payrolls`, item)
        .then((res) => {
          if (res.status === 200) {
            this.listPayrolls = res.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },

    exportExcel() {
      if (this.payroll_id == "") return;
      this.isLoading = true;
      this.$axios
        .get(`company/list/export/tax/payroll/${this.payroll_id}`, {
          responseType: "blob",
        })
        .then((res) => {
          const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileUrl;
          fileLink.setAttribute("download", "tax-report.xlsx");
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    let date = new Date();
    let firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    this.start_date = moment(firstDate).format("YYYY-MM-DD");

    this.fetchPayrolls({
      startDate: this.start_date,
      endDate: this.end_date,
    });
  },
};
</script>

<style scoped lang="scss">
.main-report {
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: rgba(223, 230, 233, 0.3);
  padding: 8px;
  font-family: $font-family;

  h2 {
    font-size: 20px;
  }

  .card-select-date {
    padding: 8px;
  }
}

.status {
  font-size: 12px;
  letter-spacing: 1px;
  padding: 2px 8px;
  width: 60px;
  height: 20px;
  text-transform: capitalize;
  font-family: $font-family;
  border-radius: 60px;
}

.pending {
  background-color: rgba(128, 142, 155, 1);
  color: #ffffff;
}

.confirm {
  background-color: rgba(46, 204, 113, 1) !important;
  color: #ffffff;
}

.updated {
  background-color: rgba(255, 221, 89, 1) !important;
  color: #ffffff;
}

.rejected {
  background-color: rgba(192, 57, 43, 1) !important;
  color: #ffffff;
}

.approve {
  background-color: rgba(38, 222, 129, 1);
  color: #ffffff;
}

.success {
  background-color: rgba(30, 144, 255, 1) !important;
  color: #ffffff;
}
</style>
