<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1></h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-row dense>
          <v-col cols="12" md="4" lg="2" class="search">
            <label class="label-input">{{ $t("Employee.search_filter") }}</label>
            <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense
              prepend-inner-icon="mdi-magnify" placeholder="..." v-model="filter" @keypress.enter="fetchEmployeeContract"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4" lg="2">
            <label class="label-input">{{ $t("Employee.dept") }}</label>
            <v-autocomplete filled dense item-text="name" item-value="id" outlined :items="listDepartment" hide-selected
              v-model="department_id" @change="fetchEmployeeContract">
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" lg="3">
            <label class="label-input">{{ $t("Employee.branch") }}</label>
            <v-autocomplete filled dense item-text="branch_name" item-value="id" outlined :items="listBranch"
              hide-selected v-model="branch_id" @change="fetchEmployeeContract">
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.branch_name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" lg="3">
            <label class="label-input"></label><br>
            <v-btn class="btn-export">
              <span><i class="fal fa-file-excel" style="margin-right: 4px"></i></span>Export
            </v-btn>
          </v-col>
        </v-row>

      </div>
    </div>
    <div class="content">
      <v-row>
      </v-row>
      <v-row class="my-7">
        <v-col cols="12">
          <table class="table">
            <tr>
              <th width="50">#</th>
              <th width="130">{{ $t("employee_contract.table.emp_number") }}</th>
              <th>{{ $t("employee_contract.table.emp_name") }}</th>
              <th>{{ $t("employee_contract.table.gender") }}</th>
              <th>{{ $t("employee_contract.table.start_date") }}</th>
              <th>{{ $t("employee_contract.table.end_date") }}</th>
              <th>{{ $t("employee_contract.table.detail") }}</th>
            </tr>
            <tr v-for="(item, index) in employeeContractData">
              <td>{{ index + 1 }}</td>
              <td>{{ item.emp_number }}</td>
              <td>{{ item.name }} {{ item.surname }}</td>
              <td>{{ $t(`employee_contract.table.${item.gender}`) }}</td>
              <td>{{ item.start_date }}</td>
              <td><span :class="check_contract_end(item.contract_expired)">{{ item.end_date }}</span></td>
              <td>
                <v-btn class="mx-2" fab dark small color="primary" @click="show_contract_detail(item)">
                  <v-icon dark> mdi-eye </v-icon>
                </v-btn>
                <v-btn class="mx-2" fab dark small color="primary" @click="renew_the_contract(item)">
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </td>
            </tr>
          </table>
          <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset"
            @paginate="fetchEmployeeContract">
          </Pagination>
        </v-col>
      </v-row>

    </div>
    <Loading v-if="isLoading" />
    <show_contract_detail :dialog="dialogShowDetail" :employee_item="employee_item" @delete="isLoading = true"
      @success="insert_success" @close="dialogShowDetail = false" />
    <renew_contract :dialog="dialogRenewContract" :renew_item="renewItem" @close="dialogRenewContract = false"
      @save="isLoading = true" @uploaded="isLoading = false" @success="insert_success" />
  </div>
</template>

<script>
import Pagination from "@/components/Paginate/Pagination";
import Loading from "@/components/Loading";
import show_contract_detail from '@/components/employeeContract/show_contract_detail'
import renew_contract from '@/components/employeeContract/renew_contract'
export default {
  components: {
    Pagination,
    Loading,
    show_contract_detail,
    renew_contract
  },
  data() {
    return {
      renewItem: [],
      employee_item: [],
      dialogRenewContract: false,
      dialogShowDetail: false,
      isLoading: false,
      filter: "",
      listBranch: [],
      branch_id: "",
      listDepartment: [],
      department_id: "",
      employeeContractData: [],
      offset: 10,
      pagination: {},
      per_page: 10,
    }
  },
  methods: {
    check_contract_end(status) {
      if (status == 'on')
        return 'text-primary';
      if (status == 'warning')
        return 'text-warning';
      if (status == 'expired')
        return 'text-danger';
    },
    renew_the_contract(item) {
      this.renewItem = item;
      this.dialogRenewContract = true;
    },

    show_contract_detail(item) {
      this.employee_item = item;
      this.dialogShowDetail = true;
    },

    fetchDepartment() {
      this.$axios.get(`company/list/departments/selected`, {}).then((res) => {
        if (res.status === 200) {
          this.listDepartment = res.data.data;
        }
      });
    },

    fetchBranch() {
      this.$axios
        .get(`company/list/company/branches`)
        .then((res) => {
          if (res) {
            this.listBranch = res.data.companyBranches;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchEmployeeContract() {
      this.isLoading = true;
      this.$axios
        .get(`company/employee/contract`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            filter: this.searchItem,
            dept_id: this.department_id,
            branch_id: this.branch_id,
          },
        })
        .then((res) => {
          this.isLoading = false;
          if (res) {
            this.employeeContractData = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.employeeContractData.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },

    insert_success() {
      this.isLoading = false;
      this.dialogShowDetail = false;
      this.fetchEmployeeContract();
    }
  },
  created() {
    this.fetchDepartment();
    this.fetchBranch();
    this.fetchEmployeeContract();
  },
}
</script>

<style lang="scss" scoped>
.table {
  font-family: $font-family;

  th {
    font-weight: 900;
    border: 1px solid !important;
  }

  td {
    border: 1px solid !important;
  }
}

.btn-export {
  background: rgb(18, 204, 1) !important;
  color: rgb(255, 255, 255) !important;
  // height: 40px !important;
}
</style>